import React, { useState,useEffect } from 'react';
import './Navigation.css';
import HamburgerButtom from "./HamburgerButton"; // Make sure to create a corresponding CSS file for styling

const Navigation = () => {

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <HamburgerButtom/>
                {/* Insert your logo here */}

            </div>

        </nav>
    );
};

export default Navigation;
