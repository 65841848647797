import React, { useState } from 'react';
import './HamburgerButtom.css'; // Make sure to create a corresponding CSS file

const HamburgerButtom = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <button className="hamburger" onClick={toggleMenu}>
                <img className="hamburgerPic" src="icons8-menu-100.png"/>
            </button>
            {isOpen && <div className="menu">
                {/* Your menu content here */}
            </div>}
        </div>
    );
};

export default HamburgerButtom;
