import './App.css';
import React from "react";
import Navigation from "./components/Navigation/Navigation";
import Title from "./components/Welcome/Title";

function App() {
  return (
    <div className="App">
        <Title/>
            <header className="App-header">



                { <Navigation/>/* Navigation and other header content */}
            </header>
            <main className="App-main">
                <img className = "img"src="9Fromoak9s.png" alt="Oak9Digital Logo" />
                <h3 className="TitleText">Soon with you ...</h3>
            </main>
            <footer className="App-footer">

            </footer>

    </div>
  );
}

export default App;
